import * as React from "react";
import { graphql } from "gatsby";
import { ICruiseProps } from "../pageHelpers/Cruise/CruiseProps";
import { Container } from "../pageHelpers/Cruise/CruiseHelpers";
import Layout from "../components/global/Layout/Layout";
import { getParagraph } from "../utils/paragraphHelpers";
import SEO from "../components/global/SEO/SEO";

const Cruise: React.FC<ICruiseProps> = (props) => {
  const cruise = props.data.allNodeCruise.nodes[0];
  const _paragraphs = cruise?.relationships.field_sections.map(getParagraph);
  const _sailDates =
    cruise?.relationships.field_sail_date_info.map(getParagraph);
  return (
    <Layout>
      <SEO title="Cruise" />
      <Container>
        {_paragraphs}
        {_sailDates}
      </Container>
    </Layout>
  );
};
export const pageQuery = graphql`
  query CruiseQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allNodeCruise {
      nodes {
        id
        relationships {
          field_sections {
            type: __typename
            ...ParagraphPageBanner
          }
          field_sail_date_info {
            type: __typename
            ...ParagraphSailDates
          }
        }
      }
    }
  }
`;
export default Cruise;
